export default {
  features: {
    cardScoring: {
      cardInfoStep: {
        pan: {
          label: 'Karta raqami',
        },
        exp: {
          label: 'Sana',
        },
        next: 'Davom etish',
      },
      otpStep: {
        title: 'Kodni kiriting',
        code: {
          label: 'Tasdiqlash kodi',
        },
        next: 'Davom etish',
        back: 'Ortga',
      },
      resultStep: {
        title: 'Baholash',
        cardInfo: {
          title: "Karta ma'lumotlari",
          avgIncome: "Oxirgi 12 oy o'rtacha daromad",
          button: "Boshqa kartani tekshirib ko'rish",
        },
        scoringDetails: {
          transactions: {
            title: 'Tranzaksiyalar',
            amount: 'Summa',
            count: 'Miqdor',
          },
        },
        monitoring: {
          title: 'Monitoring',
          label: 'Oraliq',
          button: "So'rov yuborish",
          empty: "Tanlangan oraliq ichida ma'lumotlar topilmadi",
        },
      },
    },
  },
};
