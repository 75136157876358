// @/utils/i18n-validators.js
import * as validators from '@vuelidate/validators';
import { i18n } from '@/to-fsd/shared/lib/i18n';
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';
import { checkType } from '@/plugins/aliftech-ui/utils';

// or import { createI18nMessage } from '@vuelidate/validators'
const { createI18nMessage } = validators;

const { t } = i18n.global;

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t });

const customValidators = {
  length: len => helpers.withParams({ type: 'length', len: len }, value => helpers.len(value) === len),
  isUnique: param =>
    helpers.withParams({ type: 'isUnique' }, () => {
      if (!checkType(param.value, 'array')) return false;
      const result = param.value.reduce((acc, item) => {
        acc[item] = acc[item] ? acc[item] + 1 : 1;
        return acc;
      }, {});
      return !Object.values(result).filter(n => n > 1).length;
    }),
  // dd-mm-yyy
  isDMYDateFormat: helpers.regex(/^(0?[1-9]|[12][0-9]|3[01])[\\-](0?[1-9]|1[012])[\\-]\d{4}$/),
  uppercase: helpers.regex(/[A-Z]/),
  lowercase: helpers.regex(/[a-z]/),
  number: helpers.regex(/[0-9]/),
  specSymbol: helpers.regex(/[-@$_?&!%]/),
  itemMarking: helpers.regex(/^\d{18}(?=.+$).{20}$/),
};

// wrap each validator.
export const required = withI18nMessage(validators.required);
// validators that expect a parameter should have `{ withArguments: true }` passed as a second parameter, to annotate they should be wrapped
export const minLength = withI18nMessage(validators.minLength, { withArguments: true });
export const maxLength = withI18nMessage(validators.maxLength, { withArguments: true });
export const between = withI18nMessage(validators.between, { withArguments: true });
export const length = withI18nMessage(customValidators.length, { withArguments: true });
export const isUnique = withI18nMessage(customValidators.isUnique, { withArguments: true });
export const isDMYDateFormat = withI18nMessage(customValidators.isDMYDateFormat);
export const uppercase = withI18nMessage(customValidators.uppercase);
export const lowercase = withI18nMessage(customValidators.lowercase);
export const number = withI18nMessage(customValidators.number);
export const numeric = withI18nMessage(validators.numeric, { withArguments: true });
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true });
export const minValue = withI18nMessage(validators.minValue, { withArguments: true });
export const maxValue = withI18nMessage(validators.maxValue, { withArguments: true });
export const specSymbol = withI18nMessage(customValidators.specSymbol);
export const itemMarking = withI18nMessage(customValidators.itemMarking);

/**
 *
 * @param {Object} rules
 * @param fields
 * @param {Object} options
 * @returns {<Validation<ValidationArgs>>}
 */
export const useValidation = (rules, fields, options) => {
  return useVuelidate(rules, fields, { ...(options || {}) });
};

export default useValidation;
