<template>
  <div class="flex items-center" :class="[avatar !== false ? 'space-x-3' : null]">
    <div class="block">
      <div
        v-if="avatar !== false"
        :title="fullName"
        class="flex justify-center items-center w-10 h-10 rounded-full"
        :style="{
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundColor: !avatar ? '#' + colorFromName(fullName?.toString()).bg : undefined,
          backgroundImage: avatar ? `url(${avatar})` : undefined,
        }"
      >
        <p v-if="!avatar" class="text-white text-xs whitespace-normal">
          <small>
            {{ colorFromName(fullName?.toString()).short }}
          </small>
        </p>
      </div>
    </div>
    <div class="block -m-0.5">
      <p v-if="fullName" class="text-sm whitespace-normal font-medium p-0.5">
        {{ fullName }}
      </p>
      <p class="text-xs whitespace-nowrap p-0.5">
        {{ formatPhone(phone) }}
      </p>
    </div>
  </div>
</template>

<script>
  import { colorFromName } from '@/to-fsd/shared/lib';
  import { formatPhone } from '@/to-fsd/shared/lib/filters';

  export default {
    name: 'BaseNameCard',
    props: {
      avatar: {
        type: [String, Boolean],
        default: () => undefined,
      },
      fullName: {
        type: String,
        required: true,
      },
      phone: {
        type: String,
        default: () => undefined,
      },
    },
    methods: {
      colorFromName,
      formatPhone,
    },
  };
</script>
