<template>
  <div>
    <template v-if="file">
      <div class="flex flex-wrap justify-between items-center w-full">
        <div class="flex flex-wrap -m-2">
          <div v-viewer class="p-2 cursor-pointer">
            <img v-image-loader :src="fileUrl" class="w-16 h-16 object-cover" />
          </div>
          <div class="p-2 flex flex-col justify-between">
            <p class="text-gray-900 dark:text-white font-medium">{{ file.name }}</p>
            <p class="text-gray-400 dark:text-gray-500 font-medium text-xs flex flex-wrap items-center">
              <document-icon class="h-6 w-5" />
              <span class="ml-1">{{ fileSize(file.size) }}</span>
            </p>
          </div>
        </div>
        <at-button color="danger" size="xs" type="button" :disabled="removeDisabled" @click="handleDelete">
          {{ $t('system.btns.delete') }}
        </at-button>
      </div>
    </template>

    <at-file-select v-else :accept="accept" :label="label" @on-select="handleSelectFile" />
  </div>

  <crop-modal ref="_cropModal" @cropped="handleCropped" />
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { DocumentIcon } from '@heroicons/vue/solid';

  import { AtButton, AtFileSelect } from '@packages/aliftech-ui';

  import { fileSize } from '@/to-fsd/shared/lib/filters';

  import CropModal from './CropModal.vue';

  const props = defineProps({
    label: {
      type: String,
      required: true,
    },
    accept: {
      type: String,
      default: null,
    },
    removeDisabled: {
      type: Boolean,
      default: false,
    },
    withCropper: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: null,
    },
  });

  const emit = defineEmits(['update:file']);

  const _cropModal = ref(null);

  const fileUrl = computed(() => URL.createObjectURL(props.file));

  const handleSelectFile = formData => {
    const image = formData.get('files');

    if (props.withCropper) {
      if (image) {
        _cropModal.value.open(image);
      }
    } else {
      emit('update:file', image);
    }
  };

  const handleCropped = croppedImage => {
    emit('update:file', croppedImage);
  };

  const handleDelete = () => {
    emit('update:file', null);
  };
</script>
