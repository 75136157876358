import { createApp, h, provide } from 'vue';
import { App } from '@/to-fsd/app';
import { router } from '@/to-fsd/app';
import AtUi from './plugins/aliftech-ui';
import { tokenDecryption } from './to-fsd/shared/lib/filters';
import { setToken } from './services';
import Cookies from '@/to-fsd/shared/plugins/cookies';
import { i18n } from '@/to-fsd/shared/lib/i18n';
import AtToast from '@/plugins/aliftech-ui/toast';
import centrifuge from '@/to-fsd/shared/plugins/centrifuge';
import yandexMetrika from '@/to-fsd/shared/plugins/yandex-metrika';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { DefaultApolloClient } from '@vue/apollo-composable';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

import 'viewerjs/dist/viewer.css';
import '@/to-fsd/shared/assets/scss/main.scss';

import { directiveCopy, imageLoader, mask, money, viewer, viewerApi } from '@/to-fsd/shared/directives';
import { Money3Directive } from 'v-money3';
import { pandaClient } from './services/pandaClient';
import sentry from '@/to-fsd/shared/plugins/sentry';
import 'nprogress';
import 'nprogress/nprogress.css';
import { createPinia } from 'pinia';
import clickOutside from '@/to-fsd/shared/plugins/click-outside';

export const vueAppErrorHandlers = [];

export const addVueAppErrorHandler = handler => {
  vueAppErrorHandlers.push(handler);
};

export const removeVueAppErrorHandler = handler => {
  const index = vueAppErrorHandlers.indexOf(handler);

  if (index !== -1) {
    vueAppErrorHandlers.splice(index, 1);
  }
};

export const app = createApp({
  setup() {
    provide(DefaultApolloClient, pandaClient);
  },
  render: () => h(App),
});

app
  .use(VueQueryPlugin)
  .use(Cookies)
  .use(AtToast)
  .use(createPinia())
  .use(router)
  .use(i18n)
  .use(AtUi)
  .use(yandexMetrika)
  .use(sentry)
  .use(VueShepherdPlugin)
  .mount('#app');

app.directive('image-loader', imageLoader);
app.directive('mask', mask);
app.directive('money', money);
app.directive('click-outside', clickOutside);
app.directive('viewer', viewer);
app.config.globalProperties[`$viewerApi`] = viewerApi;
app.config.globalProperties.$toast = AtToast;
app.provide('$toast', AtToast);
app.provide('$centrifuge', centrifuge);
app.provide('checkoutRedirect', process.env.VUE_APP_PANDA_CHECKOUT_REDIRECT_URL);
app.directive('copy', directiveCopy);
app.directive('money3', Money3Directive);

app.config.errorHandler = message => {
  // eslint-disable-next-line
  console.error(message);
  vueAppErrorHandlers.forEach(handler => {
    handler(message);
  });
};

const requireComponent = require.context('@/to-fsd/shared/ui/base', true, /Base[^\\.]+.(js|vue)/);
requireComponent.keys().forEach(fileName => {
  const { default: component } = requireComponent(fileName);
  let componentName = (fileName[0].toUpperCase() + fileName.substring(1)).replace(/^\.\/(.*)\.\w+$/, '$1');
  const splitName = componentName.split('/');
  if (splitName.length) {
    const name = splitName.find(name => /At/g.test(name));
    if (name !== undefined && typeof name === 'string') {
      componentName = name;
    }
  }
  if (componentName === component?.name) {
    app.component(componentName, component);
  }
});

i18n.global.locale = app.config.globalProperties.$cookies.get('lang') || 'uz';

if (process.env.NODE_ENV !== 'test') {
  const pageContext = require.context('@/to-fsd', true, /i18n\/(ru|uz)\.js$/);

  pageContext.keys().forEach(key => {
    const locale = key.match(/\/i18n\/(ru|uz)\.js$/)[1];
    const { default: messages } = pageContext(key);

    i18n.global.mergeLocaleMessage(locale, messages);
  });
}

const token = app.config.globalProperties.$cookies.get('auth');
if (token) {
  try {
    setToken(tokenDecryption(token));
  } catch (e) {
    setToken('');
  }
}
