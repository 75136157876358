import { i18n } from '@/to-fsd/shared/lib/i18n';
import { useUserStore } from '@/to-fsd/shared/store/user';
import { useMainStore } from '@/to-fsd/shared/store/main';
import { computed } from 'vue';

const useModuleOptions = () => {
  const mainStore = useMainStore();
  const userStore = useUserStore();

  const permissionNames = computed(() => userStore.me?.permissions.map(p => p.name) || []);

  return computed(() => {
    const result = [];
    const modules = mainStore.app?.merchant?.modules;

    if (modules.includes('BNPL') && permissionNames.value.includes('merchant_alifnasiya')) {
      result.push({ value: 'module_azo', title: i18n.global.t('sidebar.modules.module_azo') });
    }
    if (
      permissionNames.value.includes('merchant_acquiring') &&
      (modules.includes('ACQUIRING') || modules.includes('BML'))
    ) {
      result.push({ value: 'module_acquiring', title: i18n.global.t('sidebar.modules.module_acquiring') });
    }
    if (modules.includes('BNPL') && permissionNames.value.includes('merchant_alifshop')) {
      result.push({ value: 'module_alifshop', title: i18n.global.t('sidebar.modules.module_alifshop') });
    }
    if (permissionNames.value.includes('merchant_admin')) {
      result.push({ value: 'module_admin', title: i18n.global.t('sidebar.modules.module_admin') });
    }

    return result;
  });
};

export default useModuleOptions;
