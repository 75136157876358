export default {
  invoice: {
    titles: {
      createInvoice: 'Создание счёт-фактуры',
    },
    documentNumberModal: {
      title: 'Введите номер документа',
      label: 'Номер документа',
      cancel: 'Отмена',
      create: 'Создать',
    },
    eimzoModal: {
      title: 'Выберите ЭЦП ключ',
      subtitle: 'ЭЦП ключи не найдены. Проверьте подключение E-imzo, наличие ЭЦП ключей и обновите страницу',
      cert: 'ЭЦП ключ',
      cancel: 'Отмена',
      apply: 'Подтвердить',
    },
    index: {
      banner: 'Данные счёт-фактуры выставляются на платформе Didox',
      tabs: {
        positiveInvoice: 'Счет-фактура',
        negativeInvoice: 'Минусовая счёт-фактура',
        draftInvoice: 'Черновики',
      },
      positive: {
        headers: {
          name: 'Наименование',
          price: 'Цена',
          count: 'Количество',
        },
        buttons: {
          insert: 'Выставить',
          sign: 'Подписать',
          download: 'Скачать',
        },
        label: 'Выберите дату для создания счет-фактуры',
      },
      negative: {
        headers: {
          title: 'Выставить минусовую счет-фактуру?',
          subtitle: 'Для этих товаров будет выставлена минусовая счёт-фактура',
          contractWithQuantity: 'Контракт / кол-во товаров',
        },
        contractDate: 'Дата контракта {date}',
        itemsQuantity: '{count} товар на возврат',
        itemName: 'Название: {name}',
        itemsAmount: 'Сумма: {amount}',
        itemsCount: 'Количество: {count}',
        buttons: {
          insert: 'Выставить',
          select: 'Выбрать',
          cancel: 'Отменить',
          sign: 'Подписать',
          download: 'Скачать',
        },
        label: 'Выберите дату для создания минусовой счет-фактуры',
      },
      draft: {
        headers: {
          created_date: 'Дата создание',
          type: 'Тип',
          doc_date: 'Дата счет-фактуры',
          count: 'Кол-во товаров',
        },
        buttons: {
          sign: 'Подписать',
          download: 'Скачать',
        },
        types: {
          positive: 'Стандартная',
          negative: 'Минусовая',
        },
      },
      alert: {
        successMessage: 'Счёт-фактура успешно выставлена',
        noData: 'Данные за {date} не найдены',
        insertedToDraft: ['Счёт-фактура на {date} успешно выставлена в Didox', 'Статус: Черновик'],
        signed: ['Счёт-фактура на {date} успешно выставлена в Didox', 'Статус: Подписан'],
        successSigned: 'Счёт-фактура успешно подписано',
        signingError: 'Ошибка при подписании',
      },
    },
  },
};
