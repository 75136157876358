<template>
  <div class="flex items-center text-sm gap-4 w-full">
    <div v-viewer class="min-w-fit">
      <img v-image-loader :src="fileUrl" class="w-24 h-24 object-cover rounded-lg" :alt="label" />
    </div>

    <div class="flex flex-col gap-4 col-span-3 w-full">
      <div>
        <p class="text-base">{{ label }}</p>
        <p class="flex text-gray-500 items-center gap-2">
          <document-text-icon class="w-5 h-5" />
          <span>{{ fileSize(clientFile.size) }}</span>
        </p>
      </div>

      <div>
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { DocumentTextIcon } from '@heroicons/vue/solid';
  import { fileSize } from '@/to-fsd/shared/lib/filters';
  import { computed } from 'vue';
  import { getFileTypeLabel } from '@/to-fsd/entities/client-file';

  const props = defineProps({
    clientFile: {
      type: Object,
      required: true,
    },
    fileType: {
      type: String,
      default: null,
    },
  });

  const fileUrl = computed(() => {
    if (props.clientFile instanceof File) {
      return URL.createObjectURL(props.clientFile);
    }

    return props.clientFile.link;
  });

  const label = computed(() => {
    if (props.clientFile instanceof File) {
      return getFileTypeLabel(props.fileType);
    }

    return getFileTypeLabel(props.clientFile.file_type_key);
  });
</script>
