<template>
  <div class="relative">
    <slot name="title"></slot>
    <div
      v-if="props.modelValue"
      tabindex="0"
      class="w-72 h-30 p-4 shadow-md absolute z-50 bg-white dark:bg-gray-800 top-6"
      @blur="emit('update:model-value', false)"
    >
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseTooltip',
  });
</script>

<script setup>
  const emit = defineEmits(['update:model-value']);
  const props = defineProps({
    modelValue: { type: Boolean, required: true },
  });
</script>
