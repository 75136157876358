import { AuthLayout } from '@/to-fsd/layouts/auth';
import { ROUTE_NAMES } from '@/to-fsd/shared/constants';
import setDocumentTitle from './middleware/setDocumentTitle';
import { RegistrationLayout } from '@/to-fsd/layouts/registration';

const authRoutes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        path: 'login',
        name: ROUTE_NAMES.login,
        component: async () => {
          const { LoginPage } = await import('@/to-fsd/pages/login');

          return LoginPage;
        },
        meta: { middlewares: [setDocumentTitle('Авторизация')] },
      },
      {
        path: 'recover-password',
        name: ROUTE_NAMES.recoverPassword,
        component: async () => {
          const { RecoverPasswordPage } = await import('@/to-fsd/pages/recover-password');

          return RecoverPasswordPage;
        },
        meta: { middlewares: [setDocumentTitle('Авторизация')] },
      },
    ],
  },
  {
    path: '/registration',
    component: RegistrationLayout,
    children: [
      {
        path: '/sign-up',
        name: ROUTE_NAMES.registrationInfo,
        component: async () => {
          const { RegistrationPage } = await import('@/to-fsd/pages/registration');

          return RegistrationPage;
        },
        meta: {
          middlewares: [setDocumentTitle('Регистрация - Основное')],
        },
      },
      {
        path: '/complete',
        name: ROUTE_NAMES.registrationComplete,
        component: async () => {
          const { RegistrationCompletePage } = await import('@/to-fsd/pages/registration-complete');

          return RegistrationCompletePage;
        },
        meta: {
          middlewares: [setDocumentTitle('Регистрация - Завершить')],
        },
      },
    ],
  },
];

export default authRoutes;
