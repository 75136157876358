<template>
  <div
    v-if="queries.notifications.isSuccess && queries.notifications.data.data.length > 0"
    class="bg-gradient-to-r from-custom-purple to-custom-indigo overflow-hidden select-none transition-all duration-300 delay-75 ease-in-out"
  >
    <div class="px-6 py-4 text-white">
      <div
        v-for="(notification, index) in queries.notifications.data.data"
        :key="index"
        :class="[slideIndex !== index ? 'hidden' : 'flex flex-col md:flex-row md:items-center']"
        class="slides fade -m-2"
      >
        <p v-if="queries.notifications.data.data.length > 1" class="p-2 flex-grow-0 hidden md:block">
          <chevron-left-icon class="cursor-pointer h-6 w-6" @click="handlePrev" />
        </p>
        <div class="p-2 flex-grow overflow-hidden overflow-ellipsis">
          <div class="max-h-11 leading-6 text-white text-sm" v-html="showNotification(notification)" />
        </div>
        <div class="p-2 flex-grow-0">
          <at-button color="white" size="xs" :to="{ name: ROUTE_NAMES.notificationsList }">
            {{ $t('features.viewRealTimeNotifications.link') }}
          </at-button>
        </div>
        <p v-if="queries.notifications.data.data.length > 1" class="p-2 flex-grow-0 hidden md:block">
          <chevron-right-icon class="cursor-pointer h-6 w-6" @click="handleNext" />
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, onMounted, onUnmounted, reactive, ref, watchEffect } from 'vue';
  import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid';
  import { useI18n } from 'vue-i18n/index';

  import { useGetNotificationsQuery } from '@/to-fsd/entities/notification';

  import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';

  import { AtButton } from '@packages/aliftech-ui';

  import { clearHtmlTags } from '@/to-fsd/shared/lib';
  import { NASIYA_ROUTE_NAMES as ROUTE_NAMES } from '@/to-fsd/shared/router';

  import { useCentrifuge } from '@/to-fsd/shared/hooks';

  const queries = reactive({
    notifications: useGetNotificationsQuery({ fresh: 1 }),
  });

  const store = useNasiyaGlobalStore();

  const { locale } = useI18n();

  const centrifuge = useCentrifuge();

  const slideIndex = ref(0);

  const storeId = computed(() => store.storeId);
  const subscription = ref(null);

  const handler = () => {
    queries.notifications.refetch();
  };

  const subscribe = () => {
    const channel = `store_notification_${storeId.value}`;

    subscription.value = centrifuge.getSubscription(channel) || centrifuge.newSubscription(channel);

    subscription.value.subscribe();

    subscription.value.on('publication', handler);
  };

  const unsubscribe = () => {
    subscription.value.off('publication', handler);
  };

  const showPrevNotification = () => {
    if (slideIndex.value === 0) {
      slideIndex.value = queries.notifications.data.data.length - 1;
    } else {
      slideIndex.value = slideIndex.value - 1;
    }
  };

  const showNextNotification = () => {
    if (slideIndex.value + 1 === queries.notifications.data.data.length) {
      slideIndex.value = 0;
    } else {
      slideIndex.value = slideIndex.value + 1;
    }
  };

  const showNotification = notification => {
    if (!clearHtmlTags(notification[`body_${locale.value}`])) {
      return locale.value === 'uz' ? notification.body_ru : notification.body_uz;
    }
    return notification[`body_${locale.value}`];
  };

  const handlePrev = () => {
    stopLoop();
    showPrevNotification();
    startLoop();
  };

  const handleNext = () => {
    stopLoop();
    showNextNotification();
    startLoop();
  };

  let showNextNotificationTimer;

  const startLoop = () => {
    showNextNotificationTimer = setTimeout(() => {
      showNextNotification();
      startLoop();
    }, 3000);
  };

  const stopLoop = () => {
    if (showNextNotificationTimer) {
      clearTimeout(showNextNotificationTimer);
    }
  };

  watchEffect(onCleanup => {
    if (queries.notifications.isSuccess && queries.notifications.data.data.length > 1) {
      startLoop();
    }

    onCleanup(() => {
      stopLoop();
    });
  });

  onMounted(() => {
    subscribe();
  });

  onUnmounted(() => {
    unsubscribe();
  });
</script>

<style lang="scss" scoped>
  .fade {
    animation-name: fade;
    animation-duration: 1s;
  }

  @keyframes fade {
    from {
      opacity: 0;
      transform: translateX(-100px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
</style>
