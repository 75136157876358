import { createI18n } from 'vue-i18n';

const i18n = createI18n({
  locale: 'ru',
  fallbackLocale: 'ru',
  silentFallbackWarn: true,
  messages: {},
});

export { i18n };
