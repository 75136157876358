<template>
  <div class="text-gray-900 dark:text-white">
    <template v-if="step === 'initial'">
      <slot name="verify" :verify="verify" :loading="loading">
        <div class="flex flex-col items-stretch mt-3">
          <at-button color="primary" :loading="loading" :disabled="loading" @click="verify()">
            {{ $t('system.confirmation.process') }}
          </at-button>
        </div>
      </slot>
    </template>
    <template v-else>
      <slot
        name="store"
        :store="store"
        :loading="loading"
        :action="action"
        :formatted-phone="formattedPhone"
        :cancel="cancel"
      >
        <form class="-m-0.5" @submit.prevent.stop="store">
          <div class="p-0.5">
            <at-input v-model="codeField" mask="####" :error="error"></at-input>
          </div>
          <div v-if="formattedPhone" class="p-0.5">
            <at-alert v-if="step === 'code'" type="success">
              <i18n-t keypath="system.confirmation.code">
                <template #phone>
                  <strong class="whitespace-nowrap">{{ formattedPhone }}</strong>
                </template>
              </i18n-t>
            </at-alert>
            <at-alert v-if="step === 'call'" type="success">
              <i18n-t keypath="system.confirmation.call">
                <template #phone>
                  <strong class="whitespace-nowrap">{{ formattedPhone }}</strong>
                </template>
              </i18n-t>
            </at-alert>
          </div>
          <div class="p-0.5">
            <base-otp-info-block />
          </div>
          <div class="mt-1 p-0.5 space-y-3 flex flex-col">
            <at-button
              type="submit"
              color="primary"
              :loading="loading && action === 'store'"
              :disabled="loading && action === 'store'"
            >
              {{ $t('system.confirmation.confirm') }}
            </at-button>
            <at-button
              type="button"
              color="secondary"
              :loading="loading && action === 'verify'"
              :disabled="loading"
              @click.prevent.stop="verify({ dial: true })"
            >
              {{ $t('system.confirmation.orderCall') }}
            </at-button>
            <at-button type="button" :disabled="loading" @click="cancel">
              {{ $t('system.confirmation.cancel') }}
            </at-button>
          </div>
        </form>
      </slot>
    </template>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'BaseConfirmation',
  });
</script>

<script setup>
  import { computed, ref } from 'vue';
  import { formatPhone } from '@/to-fsd/shared/lib/filters';
  import { AtAlert, AtInput, AtButton } from '@packages/aliftech-ui';

  const props = defineProps({
    code: { type: [String, Number], default: () => undefined },
    step: { type: String, default: () => 'initial' },
    loading: { type: [String, Number, Boolean], default: () => false },
    phone: { type: [String, Number], default: () => '' },
    error: { type: String, default: () => undefined },
  });

  const formattedPhone = computed(() => formatPhone(props.phone));

  const emit = defineEmits(['update:code', 'verify', 'store', 'cancel']);
  const codeField = computed({
    get: () => {
      return props.code;
    },
    set: value => {
      emit('update:code', value);
    },
  });

  const action = ref(undefined);

  const verify = (params = {}) => {
    action.value = 'verify';
    emit('verify', params);
  };

  const store = () => {
    action.value = 'store';
    emit('store', true);
  };

  const cancel = () => {
    emit('cancel', true);
    action.value = undefined;
  };
</script>
