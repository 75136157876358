export default {
  features: {
    cardScoring: {
      cardInfoStep: {
        pan: {
          label: 'Номер карты',
        },
        exp: {
          label: 'Cрок',
        },
        next: 'Продолжить',
      },
      otpStep: {
        title: 'Введите код',
        code: {
          label: 'Код подтверждения',
        },
        next: 'Продолжить',
        back: 'Назад',
      },
      resultStep: {
        title: 'Оценка',
        cardInfo: {
          title: 'Реквизиты карты',
          avgIncome: 'Средний доход за 12 месяцев',
          button: 'Проверить другую карту',
        },
        scoringDetails: {
          transactions: {
            title: 'Транзакции за',
            amount: 'Сумма',
            count: 'Количество',
          },
        },
        monitoring: {
          title: 'Мониторинг',
          label: 'Период',
          button: 'Запросить',
          empty: 'За выбранный период данные не найдены',
        },
      },
    },
  },
};
