<template>
  <at-dropdown
    :icon="theme === 'dark' ? 'moon' : 'sun'"
    :title="$t('features.toggleTheme.toggle.label')"
    position="right"
  >
    <at-dropdown-item
      icon="sun"
      :title="$t('features.toggleTheme.toggle.options[0]')"
      :active="theme === 'light'"
      @click="setTheme('light')"
    />
    <at-dropdown-item
      icon="moon"
      :title="$t('features.toggleTheme.toggle.options[1]')"
      :active="theme === 'dark'"
      @click="setTheme('dark')"
    />
  </at-dropdown>
</template>

<script setup>
  import { AtDropdown, AtDropdownItem } from '@packages/aliftech-ui';
  import { useTheme } from '@/to-fsd/shared/hooks';

  const { theme, setTheme } = useTheme();
</script>
