// import * as Sentry from '@sentry/vue';

export default {
  install() {
    // if (process.env.VUE_APP_ENVIRONMENT === 'production') {
    //   Sentry.init({
    //     app,
    //     dsn: process.env.VUE_APP_SENTRY_DSN,
    //     logErrors: true,
    //     attachProps: true,
    //     attachStacktrace: true,
    //     integrations: [],
    //     ignoreErrors: [
    //       'Avoided redundant navigation to current location',
    //       /Navigation cancelled from .* to .* with a new navigation/,
    //       'Navigation cancelled from',
    //     ],
    //   });
    // }
  },
};
