import { AlifshopLayout } from '@/to-fsd/layouts/alifshop';
import { ALIFSHOP_ROUTE_NAMES } from '@/to-fsd/shared/router';
import setDocumentTitle from './middleware/setDocumentTitle';
import requireAuthToken from './middleware/requireAuthToken';
import fetchAuthUser from './middleware/fetchAuthUser';
import fetchPrmApp from './middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from './middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from './middleware/requireAccessToModule';
import requirePermissions from './middleware/requirePermissions';

const routes = [
  {
    path: '/alifshop/',
    component: AlifshopLayout,
    redirect: '/alifshop/orders',
    children: [
      {
        path: '/alifshop/orders',
        name: ALIFSHOP_ROUTE_NAMES.ordersList,
        component: async () => {
          const { AlifshopOrdersPage } = await import('@/to-fsd/pages/alifshop-orders');

          return AlifshopOrdersPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Заказы'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_alifshop'),
            requirePermissions(['merchant_alifshop']),
          ],
        },
      },
      {
        path: '/alifshop/orders/:alifshopOrderId/to-fsd',
        name: ALIFSHOP_ROUTE_NAMES.ordersShow,
        component: async () => {
          const { AlifshopOrdersShowPage } = await import('@/to-fsd/pages/alifshop-orders-show');

          return AlifshopOrdersShowPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Заказы'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_alifshop'),
            requirePermissions(['merchant_alifshop']),
          ],
        },
        props: true,
      },
      {
        path: '/alifshop/orders/:alifshopOrderId/deliveries/create',
        name: ALIFSHOP_ROUTE_NAMES.ordersDeliveryCreate,
        component: async () => {
          const { AlifshopOrderCreateDeliveryPage } = await import('@/to-fsd/pages/alifshop-order-create-delivery');

          return AlifshopOrderCreateDeliveryPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Создание доставки'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_alifshop'),
            requirePermissions(['merchant_alifshop']),
          ],
        },
        props: true,
      },
    ],
  },
];

export default routes;
