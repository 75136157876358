import { AdminLayout } from '@/to-fsd/layouts/admin';
import { ADMIN_ROUTE_NAMES as ADMIN_ROUTES } from '@/to-fsd/shared/router';
import setDocumentTitle from './middleware/setDocumentTitle';
import requireAuthToken from './middleware/requireAuthToken';
import fetchAuthUser from './middleware/fetchAuthUser';
import fetchPrmApp from './middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from './middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from './middleware/requireAccessToModule';
import requirePermissions from './middleware/requirePermissions';

const adminRoutes = [
  {
    path: '/admin/',
    component: AdminLayout,
    children: [
      {
        path: '/admin/employees',
        name: ADMIN_ROUTES.employeesList,
        component: async () => {
          const { EmployeesListPage } = await import('@/to-fsd/pages/employees-list');

          return EmployeesListPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Сотрудники'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_admin'),
            requirePermissions(['merchant_admin']),
          ],
        },
      },
      {
        path: '/admin/employees/:employeeId',
        name: ADMIN_ROUTES.employeesShow,
        component: async () => {
          const { AdminEmployeeShowPage } = await import('@/to-fsd/pages/admin-employee-show');

          return AdminEmployeeShowPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Сотрудники'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_admin'),
            requirePermissions(['merchant_admin']),
          ],
        },
      },
      {
        path: '/admin/pos',
        name: ADMIN_ROUTES.posList,
        component: async () => {
          const { PosTerminalsPage } = await import('@/to-fsd/pages/pos-terminals');

          return PosTerminalsPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Кассы'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_admin'),
            requirePermissions(['merchant_admin']),
          ],
        },
      },
    ],
  },
];

export default adminRoutes;
