import { ROUTE_NAMES } from '@/to-fsd/shared/constants';
import setDocumentTitle from './middleware/setDocumentTitle';
import requireAuthToken from './middleware/requireAuthToken';
import fetchAuthUser from './middleware/fetchAuthUser';
import fetchPrmApp from './middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from './middleware/requireAccessToAtLeastOneModule';
import { OfflinePage } from '@/to-fsd/pages/offline';

const commonRoutes = [
  {
    path: '/',
    name: ROUTE_NAMES.home,
    component: async () => {
      const { RootPage } = await import('@/to-fsd/pages/root');

      return RootPage;
    },
    meta: {
      middlewares: [
        setDocumentTitle('Главная'),
        requireAuthToken,
        fetchAuthUser,
        fetchPrmApp,
        requireAccessToAtLeastOneModule,
      ],
    },
  },
  {
    path: '/no-permission',
    name: ROUTE_NAMES.noPermission,
    component: async () => {
      const { ErrorPage } = await import('@/to-fsd/pages/error-page');

      return ErrorPage;
    },
    meta: { middlewares: [setDocumentTitle('Недостаточно прав')] },
  },
  {
    path: '/module-access-denied',
    name: ROUTE_NAMES.moduleAccessDenied,
    component: async () => {
      const { ErrorPage } = await import('@/to-fsd/pages/error-page');

      return ErrorPage;
    },
    meta: { middlewares: [setDocumentTitle('Доступ к модулю запрещен')] },
  },
  {
    path: '/offline',
    name: ROUTE_NAMES.offline,
    component: OfflinePage,
    meta: { middlewares: [setDocumentTitle('Нет интернета')] },
  },
  {
    path: '/object-not-found',
    name: ROUTE_NAMES.objectNotFound,
    component: async () => {
      const { ErrorPage } = await import('@/to-fsd/pages/error-page');

      return ErrorPage;
    },
    meta: { middlewares: [setDocumentTitle('Объект не найден')] },
  },
  {
    path: '/server-error',
    name: ROUTE_NAMES.serverError,
    component: async () => {
      const { ErrorPage } = await import('@/to-fsd/pages/error-page');

      return ErrorPage;
    },
    meta: { middlewares: [setDocumentTitle('Ошибка сервера')] },
  },
  {
    path: '/:pathMatch(.*)',
    name: ROUTE_NAMES.pageNotFound,
    component: async () => {
      const { ErrorPage } = await import('@/to-fsd/pages/error-page');

      return ErrorPage;
    },
    meta: { middlewares: [setDocumentTitle('Страница не найдена')] },
  },
  {
    path: '/clients/upload-file',
    name: ROUTE_NAMES.uploadClientFile,
    component: async () => {
      const { UploadClientFilePage } = await import('@/to-fsd/pages/upload-client-file');

      return UploadClientFilePage;
    },
    meta: {
      middlewares: [setDocumentTitle('Загрузить файл клиента')],
    },
  },
];

export default commonRoutes;
