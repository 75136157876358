export default {
  applications: {
    show: {
      settings: 'Настройки',
      downPayment: 'Предоплата',
      generate: 'Сгенерировать акт',
      contractLanguage: {
        ru: 'На русском',
        uz: 'На узбекском',
      },
      showContract: 'Показать акт',
      files: {
        title: 'Файл контракта',
        common: 'Файл',
      },
      notifications: {
        statusChanged: 'Статус заявки изменен',
      },
    },
    tabs: {
      applicationInfo: 'О заявке',
      paymentsScheduleAndDetails: 'Детали и график оплаты',
    },
    titles: {
      rejected: 'Отказано',
      cancelled: 'Отменено',
    },
    status: {
      new: {
        title: 'Все шаги успешно заполнены',
        signed: 'Соглашение было подписано. Подписал(а) — {name}.',
        canBeSent: 'Можно отправить его на рассмотрение.',
        createDownPayment: 'Внести предоплату',
        cancelDownPayment: 'Отменить предоплату',
        hintDownPayment:
          'Если лимита не достаточно для приобретения данного товара — предложите клиенту внести предоплату',
      },
      reviewing: {
        title: 'Заявка находится на рассмотрении',
        body: 'Пожалуйста, подождите окончания рассмотрения.',
      },
      send: 'Отправить',
      cancel: 'Отменить',
    },
    comments: {
      title: 'Комментарии',
    },
    paymentSchedule: {
      title: 'График платежей',
      headers: {
        balanceBefore: 'Остаток до',
        balanceAfter: 'Остаток после',
        date: 'Дата',
        payment: 'Платеж',
      },
      btns: {
        close: 'Закрыть',
        print: 'Печать',
      },
    },
    condition: {
      title: 'Ежемесячная оплата',
      month: 'Месяц',
      months: 'Месяцев',
      commission: 'Комиссия',
      credit: 'Рассрочка',
      sumPerMonth: 'сум / мес',
      edit: 'Изменить',
    },
    creditCreate: {
      soldToAlif: {
        confirm: 'Продать Алифу',
        cancel: 'Отменить',
        segregated: 'Продавец отделил и передал товар(ы) для Alif Moliya',
        beforeInfo: [
          'ПРЕДЛОЖЕНИЕ ОТ ООО "Alif Moliya"',
          'ООО "Alif Moliya" покупает указанный(ые) в заявке товар(ы) по согласованной цене. Нажимая кнопку «Продать Алифу», вы принимаете данное предложение и передаете ООО "Alif Moliya" право собственности на продукцию, указанную в заявке.',
        ],
        afterInfo: 'Право собственности на данные товары успешно передано ООО "Alif Moliya"',
      },
      inPlace: {
        title: 'На месте',
        header: 'Оформление рассрочки',
        sendSms: 'Отправить смс для подтверждения выдачи рассрочки.',
        caution: ['После подтверждения ', 'рассрочку нельзя отменить!'],
        process: 'Оформить',
      },
    },
    rejects: {
      title: 'Возвраты',
      headers: {
        naming: 'Наименование товаров',
        status: 'Статус',
        type: 'Тип',
        date: 'Дата',
      },
      allItems: 'Все товары',
      full: 'Полный',
      partial: 'Частичный',
      errors: {
        noRejects: 'Нет возврата',
      },
    },
    delivery: {
      status: 'Статус доставки',
      address: 'Адрес',
      nearby: 'Ориентир',
      note: 'Заметка',
      cancel: 'Отменить',
      self: {
        success: {
          created: 'Для данной заявки была создана Своя доставка',
        },
      },
      dpd: {
        create: 'Доставка Алифа',
        success: {
          created: 'Для данной заявки была создана доставка',
        },
      },
    },
    transfer: {
      title: 'Перекинуть на другой магазин',
      store: 'Магазин',
      save: 'Сохранить',
      cancel: 'Отменить',
      success: {
        title: 'Успешно',
        subTitle: 'Заявка перекинут на указанный магазин',
      },
    },
    holding: {
      title: 'Внести предоплату',
      subtitle: 'Сумма предоплаты не может быть меньше, чем разница между суммой покупки и лимитом клиента',
      save: 'Оплатить',
      cancel: 'Отменить',
      success: {
        title: 'Оплата успешно проведена',
      },
      amount: {
        label: 'Сумма предоплаты',
        placeholder: '0',
        hint: 'Предпочитаемая сумма оплаты',
      },
    },
    cancelHolding: {
      title: 'Отменить предоплату',
      subtitle: 'Вы точно уверены что хотите отменить предоплату ?',
      save: 'Да',
      cancel: 'Нет',
      success: {
        title: 'Предоплата успешно отменена',
      },
    },
    cancel: {
      title: 'Отменить',
      button: 'Отменить',
      save: 'Сохранить',
      chooseReason: 'Пожалуйста, выберите причину отмены заявки',
    },
    confirmCancel: {
      title: 'Вы уверены?',
      subTitle: 'Вы можете продолжить эту заявку, изменив товары, срок и дату погашения без создания новой заявки.',
      btns: {
        cancel: 'Отмена',
        confirm: 'Продолжить',
      },
    },
    create: {
      button: 'Создать заявку',
      items: {
        title: 'Товары',
      },
      conditions: {
        title: 'Условия',
        commission: 'Комиссия',
        creditAmount: 'Сумма рассрочки',
        applicationAmount: 'Сумма заявки',
        downPaymentAmount: 'Сумма предоплаты',
        sumPerMonth: 'Сум/месяц',
        months: 'Месяцев',
      },
      itemNotChosen: {
        title: 'Вы не выбрали товар',
        body: 'Выбрать товар',
      },
      conditionNotChosen: {
        title: 'Вы не выбрали условие',
        body: 'Выбрать условие',
      },
      noActiveConditions: {
        title: 'У вас нет активных условий',
      },
      firstPaymentDate: {
        alert: {
          title: 'Выберете удобную дату погашения для клиента.',
          subtitle:
            'Уточните, пожалуйста, какого числа клиенту будет удобно оплачивать в связи с начислением зарплаты.',
        },
        title: 'Дата первой оплаты',
        description: 'След. дата погашения - {date}',
      },
      confirm: {
        title: 'Создать',
      },
    },
    index: {
      title: 'Заявки',
      createQrApplication: 'Создать QR заявку',
      engage: 'Обработать',
      notEngaged: 'Не обработан',
      filters: {
        button: 'Фильтр',
        cancelReason: {
          label: 'Причина отмены',
          placeholder: 'Фильтр по причине отмены',
        },
        rejectReason: {
          label: 'Причина отказа',
          placeholder: 'Фильтр по причине отказа',
        },
        source: {
          label: 'Источник',
          placeholder: 'Фильтр по источнику',
        },
        contract: {
          label: 'Скан акта',
          placeholder: 'Фильтр по скану акта',
          options: {
            uploaded: 'Загружен',
            notUploaded: 'Не загружен',
          },
        },
        store: {
          label: 'Магазин',
          placeholder: 'Фильтр по магазинам',
        },
        date: {
          label: 'Дата создания',
          placeholder: 'Фильтр по дате создания',
        },
      },
      headers: {
        client: 'Клиент',
        sum: 'Сумма',
        status: 'Статус',
        created: 'Создано',
        updated: 'Обновлено',
        engaged: 'Обработал',
        store: 'Магазин',
      },
      filterReset: 'Сбросить фильтр',
      useSearchToFindCompletedApplicationsAlert: 'Воспользуйтесь поиском, чтобы найти оформленные заявки',
    },
    items: {
      title: 'Товары',
      deletedTitle: 'Удаленные товары',
      totalSum: 'Общая сумма',
      headers: {
        amount: 'Кол-во',
        type: 'Тип',
        naming: 'Наименование',
        price: 'Цена',
        sku: 'Sku',
      },
      applicationDetails: 'Подробнее о заявке',
    },
    alifshop: {
      item: {
        label: 'Название товара',
        placeholder: 'Название и артикул товара',
        quantity: 'Количество',
      },
      complete: 'Завершить',
      notInStock: 'Данный товар на складе отсутствует',
      moreThenInStock: 'Количество товара в заявке больше чем количество товара на складе.',
      updateQuantity: 'Пожалуйста обновите количество через склад',
    },
    mark: {
      title: 'Маркировка',
      fill: 'Заполнить маркировку',
      description: 'У некоторых товаров отсутствует маркировка, заполните ее вручную',
      modal: {
        title: 'Укажите маркировку для следующих товаров',
        cancel: 'Отмена',
        save: 'Сохранить',
        success: 'Маркировка успешно сохранена',
        markingFormatAlert:
          'Маркировка должна состоять из минимум 38 символов (первые 18 символов должны быть цифрами)',
      },
    },
    uzimei: {
      title: 'IMEI',
      fill: 'Заполнить IMEI',
      description: 'У некоторых товаров отсутствует IMEI, заполните ее вручную',
      modal: {
        firstPlaceholder: 'IMEI 1',
        secondPlaceholder: 'IMEI 2',
        title: 'Укажите IMEI для следующих товаров',
        cancel: 'Отмена',
        save: 'Сохранить',
        success: 'IMEI успешно сохранена',
        delete: 'IMEI успешно удалена',
        imeiFormatAlert: 'IMEI код должен состоять из 15 цифр',
      },
    },
  },
};
