<template>
  <template v-if="error">
    <api-error :error="error" />
  </template>

  <div v-else class="block w-full">
    <at-table :head="tableHeaders" :elements="isLoading ? new Array(15).fill(true) : notifications?.data">
      <template #element="{ element }">
        <template v-if="isLoading">
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }"></at-skeleton>
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }"></at-skeleton>
          </at-table-item>
          <at-table-item>
            <at-skeleton :style="{ height: 24 / 16 + 'rem' }"></at-skeleton>
          </at-table-item>
        </template>
        <template v-else>
          <at-table-item class="align-top">
            <div v-html="element[`title_${locale}`]"></div>
          </at-table-item>
          <at-table-item class="align-top">
            <div v-html="showNotification(element)"></div>
          </at-table-item>
          <at-table-item class="align-top">
            <p class="whitespace-nowrap">
              {{ formatDate(element?.created_at) }}
            </p>
          </at-table-item>
        </template>
      </template>
      <template v-if="notifications?.meta?.last_page > 1" #footer>
        <div class="flex flex-wrap justify-end">
          <at-pagination
            :model-value="page"
            :total="notifications?.meta?.last_page"
            @update:model-value="$emit('update:page', $event)"
          />
        </div>
      </template>
    </at-table>

    <div v-if="!isLoading && !notifications?.data?.length" class="block mt-4">
      <at-alert type="info">{{ $t('system.errors.noData') }}</at-alert>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { useI18n } from 'vue-i18n/index';

  import { formatDate } from '@/to-fsd/shared/lib/filters';
  import { clearHtmlTags } from '@/to-fsd/shared/lib';
  import { ApiError } from '@/to-fsd/shared/lib';
  import { AtAlert, AtPagination, AtTable, AtTableItem, AtSkeleton } from '@packages/aliftech-ui';

  defineProps({
    notifications: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
    error: {
      type: Object,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
  });

  defineEmits(['update:page']);

  const { t, locale } = useI18n();

  const tableHeaders = computed(() => [
    { title: t('entities.notification.notificationsList.headers[0]') },
    { title: t('entities.notification.notificationsList.headers[1]') },
    { title: t('entities.notification.notificationsList.headers[2]') },
  ]);

  const showNotification = notification => {
    if (!clearHtmlTags(notification[`body_${locale.value}`])) {
      return locale.value === 'uz' ? notification.body_ru : notification.body_uz;
    }
    return notification[`body_${locale.value}`];
  };
</script>
