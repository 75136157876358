import { AcquiringLayout } from '@/to-fsd/layouts/acquiring';
import { ACQUIRING_ROUTE_NAMES } from '@/to-fsd/shared/router';
import setDocumentTitle from './middleware/setDocumentTitle';
import requireAuthToken from './middleware/requireAuthToken';
import fetchAuthUser from './middleware/fetchAuthUser';
import fetchPrmApp from './middleware/fetchPrmApp';
import requireAccessToAtLeastOneModule from './middleware/requireAccessToAtLeastOneModule';
import requireAccessToModule from './middleware/requireAccessToModule';
import requirePermissions from './middleware/requirePermissions';
import fetchNasiyaResources from './middleware/fetchNasiyaResources';

const acquiringRoutes = [
  {
    path: '/acquiring/',
    component: AcquiringLayout,
    redirect: '/acquiring/purchases',
    children: [
      {
        path: '/acquiring/purchases',
        name: ACQUIRING_ROUTE_NAMES.balancesList,
        component: async () => {
          const { AcquiringBalancesPage } = await import('@/to-fsd/pages/acquiring-balances');

          return AcquiringBalancesPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Список оплат'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_acquiring'),
            requirePermissions(['merchant_acquiring']),
            fetchNasiyaResources,
          ],
        },
      },
      {
        path: '/acquiring/balances/create-invoice',
        name: ACQUIRING_ROUTE_NAMES.createInvoice,
        component: async () => {
          const { AcquiringCreateInvoicePage } = await import('@/to-fsd/pages/acquiring-create-invoice');

          return AcquiringCreateInvoicePage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Cоздание инвойса'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_acquiring'),
            requirePermissions(['merchant_acquiring']),
          ],
        },
      },
      {
        path: '/acquiring/card-scoring',
        name: ACQUIRING_ROUTE_NAMES.cardScoring,
        component: async () => {
          const { CardScoringPage } = await import('@/to-fsd/pages/card-scoring');

          return CardScoringPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Оценка карт'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_acquiring'),
            requirePermissions(['merchant_acquiring', 'merchant_scoring_card']),
          ],
        },
      },
      {
        path: '/acquiring/clients/search',
        name: ACQUIRING_ROUTE_NAMES.searchClients,
        component: async () => {
          const { BmlSearchClientsPage } = await import('@/to-fsd/pages/bml-search-clients');

          return BmlSearchClientsPage;
        },
        meta: {
          middlewares: [
            setDocumentTitle('Поиск клиентов'),
            requireAuthToken,
            fetchAuthUser,
            fetchPrmApp,
            requireAccessToAtLeastOneModule,
            requireAccessToModule('module_acquiring'),
            requirePermissions(['merchant_acquiring']),
          ],
        },
      },
    ],
  },
];

export default acquiringRoutes;
