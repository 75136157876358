import { createRouter, createWebHistory } from 'vue-router';
import { useMainStore } from '@/to-fsd/shared/store/main';
import { i18n } from '@/to-fsd/shared/lib/i18n';
import NProgress from 'nprogress';
import alifshopRoutes from './alifshopRoutes';
import nasiyaRoutes from './nasiyaRoutes';
import acquiringRoutes from './acquiringRoutes';
import adminRoutes from './adminRoutes';
import authRoutes from './authRoutes';
import commonRoutes from './commonRoutes';

const router = createRouter({
  history: createWebHistory(),
  routes: [...nasiyaRoutes, ...alifshopRoutes, ...acquiringRoutes, ...adminRoutes, ...authRoutes, ...commonRoutes],
});

const visitedRoutes = new Set();

router.beforeEach((to, from, next) => {
  if (!visitedRoutes.has(to.name)) {
    NProgress.set(0.6);
    NProgress.start();
  }

  next();
});

router.afterEach(to => {
  if (!visitedRoutes.has(to.name)) {
    NProgress.done();
    visitedRoutes.add(to.name);
  }
});

const middlewarePipeline = (context, middleware, index) => {
  const nextMiddleware = middleware[index];

  if (!nextMiddleware) {
    return () => true;
  }

  return () => {
    const nextPipeline = middlewarePipeline(context, middleware, index + 1);

    return nextMiddleware({ ...context, next: nextPipeline });
  };
};

export let prevRoute = null;

router.beforeEach(async (to, from) => {
  prevRoute = from;
  const mainStore = useMainStore();

  if (!mainStore.lang) {
    mainStore.$patch({ lang: i18n.global.locale || 'uz' });
  }

  const context = {
    to,
    from,
  };

  const middlewares = to.meta?.middlewares || [];

  if (middlewares.length === 0) {
    return true;
  }

  return middlewares[0]({
    ...context,
    next: middlewarePipeline(context, middlewares, 1),
  });
});

export const routeErrorHandlers = [];

export const addRouteErrorHandler = handler => {
  routeErrorHandlers.push(handler);
};

export const removeRouteErrorHandler = handler => {
  const index = routeErrorHandlers.indexOf(handler);

  if (index !== -1) {
    routeErrorHandlers.splice(index, 1);
  }
};

router.onError(error => {
  routeErrorHandlers.map(handler => {
    handler(error);
  });
});

export default router;
