import { i18n } from '@/to-fsd/shared/lib/i18n';
import { checkType } from '@/plugins/aliftech-ui/utils/checkType';
import { format, parseISO, isValid } from 'date-fns';
import { enUS, ru, uz } from 'date-fns/locale';

const getLocale = locale => {
  switch (locale) {
    case 'ru':
      return ru;
    case 'uz':
      return uz;
    case 'en':
      return enUS;
    default:
      return ru;
  }
};

export const stringToUri = str => {
  if (typeof window !== 'undefined' && 'encodeURI' in window) {
    try {
      return window.encodeURI(str);
    } catch (e) {
      return str + '';
    }
  }
  return str + '';
};
export const uriToString = str => {
  if (typeof window !== 'undefined' && 'decodeURI' in window) {
    try {
      return window.decodeURI(str);
    } catch (e) {
      return str + '';
    }
  }
  return str + '';
};
export const toBase64 = str => {
  if (typeof window !== 'undefined' && 'btoa' in window) {
    try {
      return window.btoa(str);
    } catch (e) {
      return str + '';
    }
  }
  return str + '';
};
export const inBase64 = str => {
  if (typeof window !== 'undefined' && 'atob' in window) {
    try {
      return window.atob(str);
    } catch (e) {
      return str + '';
    }
  }
  return str + '';
};
export const tokenEncoding = token => {
  const result = toBase64(stringToUri(token));
  return toBase64(result.slice(0, 5) + 'A' + result.slice(5));
};
export const tokenDecryption = token => {
  const result = inBase64(token);
  return uriToString(inBase64(result.slice(0, 5) + result.slice(6)));
};

export const capitalizeFirst = text => {
  if (!checkType(text, 'string')) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Метод форматирования даты
 * @param _date
 * @param _format
 * @param _locale
 * @return {string}
 */
export const formatDate = (_date, _format = 'dd MMM yyyy', _locale) => {
  if (!_date) return _date;
  const locale = _locale || i18n.global.locale || 'ru';
  const parsedDate = parseISO(_date);
  let formattedDate;
  if (isValid(parsedDate)) {
    formattedDate = format(parsedDate, _format, {
      locale: getLocale(locale),
    });
  } else {
    try {
      formattedDate = format(new Date(_date), _format, {
        locale: getLocale(locale),
      });
    } catch {
      formattedDate = _date;
    }
  }
  return formattedDate;
};

export const formatDateOneMonthAgo = date =>
  date
    ? new Date(date.toLocaleString()).setMonth(new Date().getMonth() - 1)
    : new Date().setMonth(new Date().getMonth() - 1);

export const formatTime = date => {
  if (!date) return '';
  const dateObj = new Date(date);
  const minutes = dateObj.getMinutes() < 10 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes();
  const hours = dateObj.getHours();
  let dayPeriod = '';
  if (hours > 4 && hours < 12) {
    dayPeriod = (i18n?.global?.locale ?? 'ru') === 'ru' ? 'утра' : 'tong.';
  } else if (hours >= 12 && hours < 16) {
    dayPeriod = (i18n?.global?.locale ?? 'ru') === 'ru' ? 'дня' : 'kun.';
  } else if (hours >= 16 && hours < 22) {
    dayPeriod = (i18n?.global?.locale ?? 'ru') === 'ru' ? 'веч.' : 'kech.';
  } else {
    dayPeriod = (i18n?.global?.locale ?? 'ru') === 'ru' ? 'ночи' : 'tun.';
  }
  return `${hours}:${minutes} ${dayPeriod}`;
};

export const formatPhone = phone => {
  if (!phone) return '';
  const countryCode = `+${phone.substring(0, 3)}`;
  const companyCode = `(${phone.substring(3, 5)})`;
  const number = `${phone.substring(5, 8)}-${phone.substring(8, 10)}-${phone.substring(10)}`;

  return `${countryCode} ${companyCode} ${number}`;
};

export const strPadStart = (value, count = 6, symbol = '0') => {
  if (!value) return '';
  const str = value.toString();
  return str.padStart(count, symbol);
};

export const tiyinToSum = tiyin => {
  if (!tiyin) return '';
  return parseFloat(tiyin?.toString?.() ?? '') / 100 || 0;
};

export const sumToTiyin = (target, ...properties) => {
  if (!target) return target;
  const newTarget = { ...target };
  properties.forEach(prop => {
    newTarget[prop] = newTarget[prop] * 100;
  });
  return newTarget;
};

export const currency = (money, { type = 'tiyin', fraction = 0 } = {}) => {
  const lang = i18n?.global?.locale ?? 'ru';
  const sum = lang === 'ru' ? 'сум' : "so'm";
  if (!money) return `0 ${sum}`;
  return currencyNumber(money, type, fraction) + ' ' + sum;
};
export const currencyNumber = (number, type = 'tiyin', fraction = 2) => {
  if (!number) return '0';
  const notTiyin = type === 'tiyin' ? number / 100 : number;
  const parts = notTiyin?.toString?.().split?.('.') ?? '';
  parts[0] = parts[0]?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ' ');
  parts[1] = parts[1]?.substr(0, fraction);
  return (parts[1]?.toString?.() ?? '') === '' ? parts?.[0] : parts?.join?.('.');
};

export const shorten = value => {
  if (!value) return '';
  const string = value?.toString?.() ?? '';
  return string.length > 50 ? string.substr(0, 50).trim() + '...' : string;
};

export const fileSize = sizeInBytes => {
  const gb = 1073741824;
  const mb = 1048576;
  const kb = 1024;
  let result = '';
  if (sizeInBytes >= gb) {
    const size = parseFloat(sizeInBytes / gb).toFixed(2);
    result = `${size} GB`;
    return result;
  } else if (sizeInBytes >= mb) {
    const size = parseFloat(sizeInBytes / mb).toFixed(2);
    result = `${size} MB`;
    return result;
  } else if (sizeInBytes >= kb) {
    const size = parseFloat(sizeInBytes / kb).toFixed(2);
    result = `${size} KB`;
    return result;
  }
  result = `${sizeInBytes} Bytes`;
  return result;
};

/**
 * Метод очистки номера телефона
 * @param {String} phone
 * @return {String}
 * */
export const slicePhone = phone => {
  if (!phone) return phone;
  return phone.length > 9 ? phone.slice(-9) : phone;
};

export const formatMoney = value => {
  if (!value) {
    return value;
  }
  const money = formatNumber(value);
  return money.replace(/(\d)(?=(\d{3})+$)/g, '$1 ');
};

export const formatNumber = value => {
  if (!value) {
    return value;
  }
  return value?.toString()?.replace(/ /g, '');
};

export const maskPhoneNumber = number => {
  if (!number) return;
  number = number.toString();
  let chars = number.split('');
  chars = chars.map((item, index) => (index >= 5 && index <= 8 ? '*' : item));
  return chars.join('');
};

export default {
  capitalizeFirst,
  formatDate,
  formatDateOneMonthAgo,
  formatTime,
  formatPhone,
  strPadStart,
  tiyinToSum,
  currency,
  currencyNumber,
  shorten,
  fileSize,
  slicePhone,
  formatMoney,
  formatNumber,
  maskPhoneNumber,
};
