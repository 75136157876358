import { NASIYA_ROUTE_NAMES as NASIYA_ROUTES } from '@/to-fsd/shared/router';
import { ALIFSHOP_ROUTE_NAMES as ALIFSHOP_ROUTES } from '@/to-fsd/shared/router';
import { ACQUIRING_ROUTE_NAMES as ACQUIRING_ROUTES } from '@/to-fsd/shared/router';
import { ADMIN_ROUTE_NAMES as ADMIN_ROUTES } from '@/to-fsd/shared/router';

export const webServiceHash = {
  MERCHANT: 'MERCHANT',
  ONLINE: 'ONLINE',
  ALIFSHOP: 'ALIFSHOP',
  COMPLIANCE: 'COMPLIANCE',
  MERCHANT_MOBILE: 'MERCHANT MOBILE',
  ALIFSHOP_MOBILE: 'ALIFSHOP MOBILE',
  'E-COMMERCE': 'E-COMMERCE',
  ALIFSHOP_MINI_APP: 'ALIFSHOP MINI APP',
  ALIFMOBI: 'ALIF MOBI',
  // CRM: 'CRM',
  // PRM: 'PRM',
  // CREDITS: 'CREDITS',
  // CALLS: 'CALLS'
  // LAW: 'LAW',
};

export const knowledgeBaseVideos = [
  {
    id: 1,
    title: {
      ru: 'Добавление товаров',
      uz: "Tovar qo'shish",
    },
    thumbnailUrl: {
      ru: 'https://img.youtube.com/vi/8Fcpgx5_sjQ/maxresdefault.jpg',
      uz: 'https://img.youtube.com/vi/eafzCbXOutU/maxresdefault.jpg',
    },
    embedUrl: {
      ru: 'https://www.youtube.com/embed/8Fcpgx5_sjQ?si=6jpz5j_8GxI5h6oa',
      uz: 'https://www.youtube.com/embed/eafzCbXOutU?si=TQAND2lwcuVwibDb',
    },
  },
  {
    id: 2,
    title: {
      ru: 'Создание заявки',
      uz: 'Ariza yaratish',
    },
    thumbnailUrl: {
      ru: 'https://img.youtube.com/vi/JGhpviSgTU8/maxresdefault.jpg',
      uz: 'https://img.youtube.com/vi/K_5y5EIKGWs/maxresdefault.jpg',
    },
    embedUrl: {
      ru: 'https://www.youtube.com/embed/JGhpviSgTU8?si=kbbBLqAXtrFzT73v',
      uz: 'https://www.youtube.com/embed/K_5y5EIKGWs?si=b5IjKqPqtFaBsNvE',
    },
  },
  {
    id: 3,
    title: {
      ru: 'Удаление заявки',
      uz: 'Arizani ochirish',
    },
    thumbnailUrl: {
      ru: 'https://img.youtube.com/vi/G5e0Z99sitE/maxresdefault.jpg',
      uz: 'https://img.youtube.com/vi/vOLDP_gYZ-0/maxresdefault.jpg',
    },
    embedUrl: {
      ru: 'https://www.youtube.com/embed/G5e0Z99sitE?si=cyrQ2YXCV5SxUiz9',
      uz: 'https://www.youtube.com/embed/vOLDP_gYZ-0?si=BCeYI5_2iOxaJmXN',
    },
  },
  {
    id: 4,
    title: {
      ru: 'Дополнительные функции',
      uz: "Tizimdagi qo'shimcha funksiyalar",
    },
    thumbnailUrl: {
      ru: 'https://img.youtube.com/vi/zclvaKaOQ0Y/maxresdefault.jpg',
      uz: 'https://img.youtube.com/vi/Vd81FBRdB5E/maxresdefault.jpg',
    },
    embedUrl: {
      ru: 'https://www.youtube.com/embed/zclvaKaOQ0Y?si=iFlaKDf-rktwcRsN',
      uz: 'https://www.youtube.com/embed/Vd81FBRdB5E?si=SoQF07I4Qrtv5INM',
    },
  },
];

export const short = {
  uz: ['', 'yan..', 'fev.', 'mar.', 'apr.', 'may', 'iyun', 'iyul', 'avg.', 'sen.', 'okt.', 'noy.', 'dek.'],
  ru: ['', 'янв.', 'фев.', 'мар.', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сен.', 'окт.', 'ноя.', 'дек.'],
};

export const full = {
  uz: [
    '',
    'Yanvar',
    'Fevral',
    'Mart',
    'Aprel',
    'May',
    'Iyun',
    'Iyul',
    'Avgust',
    'Sentabr',
    'Oktabr',
    'Noyabr',
    'Dekabr',
  ],
  ru: [
    '',
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ],
};

export default {
  webServiceHash,
  short,
  full,
  knowledgeBaseVideos,
};

export const ROUTE_NAMES = {
  login: 'Auth.login',
  loginToFsd: 'Auth.login.to-fsd',
  recoverPassword: 'Auth.recover-password',
  recoverPasswordToFsd: 'Auth.recover-password.to-fsd',
  registrationInfo: 'Registration.info',
  registration: 'Registration',
  registrationComplete: 'Registration.complete',
  home: 'Home',
  noPermission: 'NoPermission',
  moduleAccessDenied: 'ModuleAccessDenied',
  offline: 'Offline',
  objectNotFound: 'ObjectNotFound',
  serverError: 'ServerError',
  pageNotFound: 'PageNotFound',
  knowledgeBase: 'KnowledgeBase',
  uploadClientFile: 'UploadClientFile',
};
export const modulesList = {
  module_azo: {
    name: 'NASIYA',
    homeRoute: NASIYA_ROUTES.home,
  },
  module_alifshop: {
    name: 'ALIFSHOP',
    homeRoute: ALIFSHOP_ROUTES.ordersList,
  },
  module_acquiring: {
    name: 'ACQUIRING',
    homeRoute: ACQUIRING_ROUTES.balancesList,
  },
  module_admin: {
    name: 'ADMIN',
    homeRoute: ADMIN_ROUTES.employeesList,
  },
};
export const modulesName = Object.keys(modulesList).reduce((acc, key) => {
  acc[modulesList[key].name] = key;
  return acc;
}, {});
