export default {
  applications: {
    show: {
      settings: 'Sozlamalar',
      downPayment: "Boshlang'ich to'lov",
      generate: 'Dalolatnoma yaratish',
      contractLanguage: {
        ru: 'Rus tilida',
        uz: "O'zbek tilida",
      },
      showContract: "Akt ko'rsatish",
      files: {
        title: 'Shartnoma fayli',
        common: 'Fayl',
      },
      notifications: {
        statusChanged: "Ariza holati o'zgardi",
      },
    },
    tabs: {
      applicationInfo: 'Ariza haqida',
      paymentsScheduleAndDetails: "To'lov grafik va tafsilotlari",
    },
    titles: {
      rejected: 'Rad etilgan',
      cancelled: 'Bekor qilingan',
    },
    status: {
      new: {
        title: 'Barcha qadamlar tugallandi',
        signed: 'Shartnoma imzolandi. Imzolagan — {name}.',
        canBeSent: "Ko'rib chiqish uchun yuborilishingiz mumkin.",
        createDownPayment: "Boshlang'ich to'lov kiritish",
        cancelDownPayment: "Boshlang'ich to'lovni bekor qilish",
        hintDownPayment:
          "Agar limit ushbu tovarni sotib olish uchun yetarli bo'lmasa — mijozga boshlang'ich to'lovni taklif qiling",
      },
      reviewing: {
        title: "Arizangiz ko'rib chiqilmoqda",
        body: "Iltimos, ko'rib chiqish tugashini kuting.",
      },
      send: 'Yuborish',
      cancel: 'Bekor qilish',
    },
    comments: {
      title: 'Sharhlar',
    },
    paymentSchedule: {
      title: "To'lov jadvali",
      headers: {
        balanceBefore: "To'lovdan oldin qoldiq",
        balanceAfter: "To'lovdan keyin qoldiq",
        date: 'Sana',
        payment: "To'lov",
      },
      btns: {
        close: 'Yopish',
        print: 'Chop etish',
      },
    },
    condition: {
      title: "Oylik to'lov",
      month: 'Oy',
      months: 'Oy',
      commission: 'Ustama',
      credit: "Muddatli to'lov",
      sumPerMonth: "so'm / oy",
      edit: 'Tahrirlash',
    },
    creditCreate: {
      soldToAlif: {
        confirm: 'Alifga sotish',
        cancel: 'Bekor qilish',
        segregated: 'Sotuvchi Alif Moliya uchun mahsulot(lar)ni ajratdi va topshirdi',
        beforeInfo: [
          'ALIF MOLIYA MCHJ TAKLIFI',
          '“Alif Moliya” MChJ arizada ko‘rsatilgan mahsulot(lar)ni kelishilgan narxda sotib oladi. “Alifga sotish” tugmasini bosish orqali siz ushbu taklifni qabul qilasiz va arizada ko‘rsatilgan mahsulotlarga egalik huquqini “Alif Moliya” MChJga o‘tkazasiz.',
        ],
        afterInfo: "Ushbu maxsulotlarning egalik huquqi «Alif Moliya» MCHJ ga muvaffaqiyatli o'tdi",
      },
      inPlace: {
        title: "Do'konda",
        header: "Muddatli to'lovni rasmiylashtirish",
        sendSms: "Muddatli to'lovni tasdiqlash uchun sms-kodni yuborish.",
        caution: ['Tasdiqlashdan keyin ', "muddatli to'lovni bekor qilib bo'lmaydi!"],
        process: 'Rasmiylashtirish',
      },
    },
    rejects: {
      title: 'Qaytarishlar',
      headers: {
        naming: 'Tovarlar nomi',
        status: 'Holat',
        type: 'Turi',
        date: 'Sana',
      },
      allItems: 'Barcha tovarlar',
      full: 'Butun',
      partial: 'Qisman',
      errors: {
        noRejects: 'Qaytarishlar mavjud emas',
      },
    },
    delivery: {
      status: 'Yetkazma holati',
      address: 'Manzil',
      nearby: "Mo'ljal",
      note: 'Eslatma',
      cancel: 'Bekor qilish',
      self: {
        success: {
          created: 'Ushbu ariza uchun Shaxsiy yetkazma yaratilgan',
        },
      },
      dpd: {
        create: 'Alif yetkazmasi',
        success: {
          created: 'Ushbu ariza uchun yetkazma yaratilgan',
        },
      },
    },
    transfer: {
      title: "Boshqa do'konga o'tkazish",
      store: "Do'kon",
      save: 'Saqlash',
      cancel: 'Bekor qilish',
      success: {
        title: "O'tkazildi",
        subTitle: "Ariza boshqa do'konga muvaffaqiyatli o'tkazildi",
      },
    },
    holding: {
      title: "Boshlang'ich to'lov kiritish",
      subtitle: "Boshlang'ich to'lov summasi xarid narxi va mijoz limiti o'rtasidagi farqdan kam bo'lmasligi kerak",
      save: "To'lash",
      cancel: 'Bekor qilish',
      success: {
        title: "To'lov muvaffaqiyatli bajarildi",
      },
      amount: {
        label: "Boshlang'ich to'lov miqdori",
        placeholder: '0',
        hint: "Istalgan to'lov summasi",
      },
    },
    cancelHolding: {
      title: "Boshlang'ich to'lovni bekor qilish",
      subtitle: "Boshlang'ich to'lovni bekor qilishni aniq istaysizmi ?",
      save: 'Xa',
      cancel: "Yo'q",
      success: {
        title: "To'lov muvaffaqiyatli bekor qilindi",
      },
    },
    cancel: {
      title: 'Bekor qilish',
      button: 'Bekor qilish',
      save: 'Saqlash',
      chooseReason: 'Iltimos, arizani bekor qilish sababini tanlang',
    },
    confirmCancel: {
      title: 'Arizani bekor qilishga aminmisiz?',
      subTitle: "Joriy arizada siz tovarlarni, to'lov muddatini va sanasini o'zgartirishingiz mumkin.",
      btns: {
        cancel: 'Bekor qilish',
        confirm: 'Davom ettirish',
      },
    },
    create: {
      button: 'Ariza yaratish',
      items: {
        title: 'Tovarlar',
      },
      conditions: {
        title: 'Shartlar',
        commission: 'Ustama',
        creditAmount: "Muttadli to'lov narxi",
        applicationAmount: 'Ariza narxi',
        downPaymentAmount: "Oldindan to'lov",
        sumPerMonth: 'Sum/Oyiga',
        months: 'Oyga',
      },
      itemNotChosen: {
        title: 'Siz biror tovarni tanlamadingiz',
        body: 'Tovar tanlash',
      },
      conditionNotChosen: {
        title: 'Shartlar tanlanmagan',
        body: 'Shartni tanlash',
      },
      noActiveConditions: {
        title: 'Sizda faol shartlar mavjud emas',
      },
      firstPaymentDate: {
        alert: {
          title: "Mijoz uchun qulay bo'lgan to'lov kunini tanlang.",
          subtitle: "Iltimos, mijozdan unga qulay bo'lgan to'lov kunini so'rang.",
        },
        title: 'Birinchi to’lov sanasi',
        description: "Keyingi to'lov sanasi - {date}",
      },
      confirm: {
        title: 'Ariza yaratish',
      },
    },
    index: {
      title: 'Arizalar',
      createQrApplication: 'QR orqali ariza berish',
      engage: 'Qabul qilish',
      notEngaged: 'Qabul qilinmagan',
      filters: {
        button: 'Filtr',
        cancelReason: {
          label: 'Bekor qilish sababi',
          placeholder: 'Bekor qilish sababi filtri',
        },
        rejectReason: {
          label: 'Rad etish sababi',
          placeholder: 'Rad etish sababi filtri',
        },
        source: {
          label: 'Manba',
          placeholder: 'Manba filtri',
        },
        contract: {
          label: 'Dalolatnoma',
          placeholder: 'Dalolatnoma filtri',
          options: {
            uploaded: 'Yuklangan',
            notUploaded: 'Yuklanmagan',
          },
        },
        store: {
          label: "Do'kon",
          placeholder: "Do'kon filtri",
        },
        date: {
          label: 'Yaratilgan sana',
          placeholder: 'Yaratilgan sana filtri',
        },
      },
      headers: {
        client: 'Mijoz',
        sum: 'Miqdori',
        status: 'Holat',
        created: 'Yaratildi',
        updated: 'Yangilandi',
        engaged: "Ko'rib chiqdi",
        store: "Do'kon",
      },
      filterReset: "Filtrlarni qayta o'rnatish",
      useSearchToFindCompletedApplicationsAlert: 'Rasmiylashtirilgan arizalarni topish uchun qidiruvdan foydalaning',
    },
    items: {
      title: 'Tovarlar',
      deletedTitle: 'Qaytarilgan tovarlar',
      totalSum: 'Jami',
      headers: {
        amount: 'Soni',
        type: 'Turi',
        naming: 'Nomi',
        price: 'Narxi',
        sku: 'Sku',
      },
      applicationDetails: 'Ariza haqida batafsil',
    },
    alifshop: {
      item: {
        label: 'Tovar nomi',
        placeholder: 'Tovar nomi va artikul',
        quantity: 'Miqdori',
      },
      complete: 'Bajarish',
      notInStock: "Bu tovar omborda yo'q",
      moreThenInStock: "Arizadagi tovar miqdori ombordagi miqdordan ko'p",
      updateQuantity: 'Iltimos, tovar miqdorini obmor orqali yangilang',
    },
    mark: {
      title: 'Belgi',
      fill: "Belgini to'ldirish",
      description: "Ba'zi mahsulotlarda belgisi yo'q, ularni qo'lda to'ldiring",
      modal: {
        title: "Quyidagi mahsulotlar uchun belgini to'ldiring",
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        success: 'Belgi muvaffaqiyati saqlandi',
        markingFormatAlert:
          "Kod kamida 38 belgidan iborat bo'lishi kerak (birinchi 18 ta belgi raqamlar bo'lishi kerak)",
      },
    },
    uzimei: {
      title: 'IMEI',
      fill: "IMEI to'ldirish",
      description: "Ba'zi mahsulotlarda IMEI kod yo'q, ularni qo'lda to'ldiring",
      modal: {
        firstPlaceholder: 'IMEI 1',
        secondPlaceholder: 'IMEI 2',
        title: "Quyidagi mahsulotlar uchun IMEI kodni to'ldiring",
        cancel: 'Bekor qilish',
        save: 'Saqlash',
        success: 'IMEI muvaffaqiyati saqlandi',
        delete: "IMEI muvaffaqiyati o'chirildi",
        imeiFormatAlert: "IMEI kod 15 ta raqamlardan iborat bo'lishi kerak",
      },
    },
  },
};
