import AtToast from '@/plugins/aliftech-ui/toast';
import { i18n } from '@/to-fsd/shared/lib/i18n';

const handler = (event, binding) => {
  const target = event.target;
  const text = binding.value || target.textContent;
  const { t } = i18n.global;

  navigator.clipboard
    .writeText(text)
    .then(() => {
      AtToast.success({ title: t('system.titles.copy') });
    })
    .catch(() => {
      AtToast.warning({ title: t('system.titles.copyError') });
    });
};

export const directiveCopy = {
  mounted(el, binding) {
    el.onclick = event => {
      handler(event, binding);
    };
  },
  unmounted(el) {
    el.onclick = null;
  },
};
