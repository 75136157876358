import { useMainStore } from '@/to-fsd/shared/store/main';
import AtToast from '@/plugins/aliftech-ui/toast';
import { app as Vue } from '@/main';
import { setToken } from '@/services';
import { ROUTE_NAMES } from '@/to-fsd/shared/constants';
import { useUserStore } from '@/to-fsd/shared/store/user';
import { useNasiyaGlobalStore } from '@/to-fsd/shared/store/nasiya-global';

const fetchPrmApp = async ({ to, next }) => {
  const mainStore = useMainStore();

  const destroyLoginDetails = () => {
    Vue.config.globalProperties.$cookies.delete('auth');
    setToken('');
    const user = useUserStore();
    const globalStore = useNasiyaGlobalStore();
    const mainStore = useMainStore();

    user.$reset();
    globalStore.$reset();
    mainStore.$reset();
  };

  if (!mainStore.app) {
    try {
      await mainStore.fetchPrmApp();
    } catch (e) {
      destroyLoginDetails();
      AtToast.error({
        title: e.message,
        duration: 7000,
      });
      return { name: ROUTE_NAMES.login, query: { from: to.fullPath } };
    }
  }

  return next();
};

export default fetchPrmApp;
