import { i18n } from '@/to-fsd/shared/lib/i18n';

const formatPrice = (amount, { type = 'tiyin', fraction = 2, withCurrencyUnit = true } = {}) => {
  const language = i18n.global.locale ?? 'ru';
  const currencyUnit = language === 'ru' ? 'сум' : "so'm";

  const adjustNumber = type === 'tiyin' ? amount / 100 : amount;
  const [integerPart, decimalPart] = String(adjustNumber).split('.') ?? ['', ''];

  const formattedIntegerPart = integerPart?.replace?.(/\B(?=(\d{3})+(?!\d))/g, ' ') ?? '';
  const truncatedDecimalPart = decimalPart?.substr(0, fraction) ?? '';

  const formattedAmount =
    truncatedDecimalPart === '' ? formattedIntegerPart : `${formattedIntegerPart}.${truncatedDecimalPart}`;

  return amount || amount === 0
    ? `${formattedAmount} ${withCurrencyUnit ? currencyUnit : ''}`
    : `0 ${withCurrencyUnit ? currencyUnit : ''}`;
};

export default formatPrice;
